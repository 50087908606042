'use strict'

###*
 # @ngdoc object
 # @name mundoAdmin.dispatch.controller:DispatchUnitTypeCodesCtrl

 # @description

###
class DispatchUnitContactsCtrl
  ### @ngInject ###
  constructor:(
    id
    $mdDialog
    DispatchUnitsManager
    DispatchUnitContactTypesManager
    ContactManager
    $q
  ) ->
    @progress = true
    @model = {}
    @errors = []

    init = =>
      @id = id
      @fields = [
        key: 'contact'
        name: 'label'
        type: 'autocomplete'
        templateOptions:
          required: true
          placeholder: 'Search for contacts'
          displayValue: 'label'
          options: ContactManager.getFullList().$object
      ,
        key: 'dispatchUnitContactType'
        name: 'type'
        type: 'select'
        templateOptions:
          label: 'Contact type'
          labelProp: "label"
          valueProp: "id"
          options: DispatchUnitContactTypesManager.getFullList().$object
      ]

      @load = load
      @add = add
      @remove = remove
      @cancel = cancel
      @submit = submit
      @contactlist = []

      @load()
    ##########

    add = ->
      @progress = true
      @model.contact = @model.contact.id if @model.contact?
      DispatchUnitsManager.addContact(@id, angular.copy(@model))
        .then(
          =>
            @model = {}
            @load()
          (resp) =>
            @errors = extractErrors(resp.data.errors.children)
            @progress = false
        )


    remove = (contactId) ->
      @progress = true
      console.log contactId
      DispatchUnitsManager.deleteContact(@id, contactId)
        .then(
          =>
            @load()
          (resp) =>
            @errors = extractErrors(resp.data.errors.children)
            @progress = false
        )

    load = ->
      DispatchUnitsManager.getContacts(@id)
        .then (data) =>
          @items = data.results
          @progress = false
          @form = {}
          @errors= []


    extractErrors = (errorsArray) ->
      errors = []
      for key, value of errorsArray
        if value.errors?
          errors.push('key': key, 'value': value.errors)
      errors

    cancel = ->
      $mdDialog.cancel()

    submit = ->
      console.log 'submit'

    init()
angular
  .module('mundoAdmin.dispatch')
  .controller 'DispatchUnitContactsCtrl', DispatchUnitContactsCtrl
